import React from 'react';

import Layout from '@components/layout'
import SEO from '@components/seo';
import Banner from '@components/banner';
import DiscoverProducts from '@components/discoverProducts';
import { CategoryAllProducts } from '@constants/app';

import '@styles/styles.scss';

const IndexPage = ({ data }) => {

  const ctf = data.contentfulDiscoverPages;
  const { 
    allProductsBannerTitle, 
    allProductsBannerSubtitle,
    allProductsBannerImageTitle,
    allProductsTitle,
    allProductsDescription
  } = ctf;
  const assets = data.allContentfulAsset.edges.map(edge => edge.node);

  const tradeUpDescription = ctf.tradeUpDescription.tradeUpDescription;
  const workUpDescription = ctf.workUpDescription.workUpDescription;
  const serviceUpDescription = ctf.serviceUpDescription.serviceUpDescription;
  const projectUpDescription = ctf.projectUpDescription.projectUpDescription;
  const checkUpDescription = ctf.checkUpDescription.checkUpDescription;
  const upFamilyDescription = ctf.upFamilyDescription.upFamilyDescription;

  return (
    <Layout>
      <SEO title="Discover"/>
      <Banner
        assets={assets}
        imageTitle={allProductsBannerImageTitle}
      >
        <div className="flex-center flex-column mt-3 mb-5 mb-md-0">
          <h1>{allProductsBannerTitle}</h1>
          <p className="text-muted">{allProductsBannerSubtitle.allProductsBannerSubtitle}</p>
        </div>
      </Banner>
      <DiscoverProducts
        productCategory={CategoryAllProducts}
        title={allProductsTitle}
        description={allProductsDescription.allProductsDescription}
        tradeUpDescription={tradeUpDescription}
        workUpDescription={workUpDescription}
        serviceUpDescription={serviceUpDescription}
        projectUpDescription={projectUpDescription}
        checkUpDescription={checkUpDescription}
        upFamilyDescription={upFamilyDescription}
      />
    </Layout>
  );
}

export default IndexPage;

export const pageQuery = graphql`
  query DiscoverAllProductsQuery {
    contentfulDiscoverPages {
      allProductsBannerTitle
      allProductsBannerSubtitle {
        allProductsBannerSubtitle
      }
      allProductsBannerImageTitle
      allProductsTitle
      allProductsDescription {
        allProductsDescription
      }
      tradeUpDescription {
        tradeUpDescription
      }
      workUpDescription {
        workUpDescription
      }
      serviceUpDescription {
        serviceUpDescription
      }
      projectUpDescription {
        projectUpDescription
      }
      checkUpDescription {
        checkUpDescription
      }
      upFamilyDescription {
        upFamilyDescription
      }
    }
    allContentfulAsset {
      edges {
        node {
          title
          file {
            contentType
            url
            fileName
          }
          fixed {
            height
            width
            src
            srcSet
          }
          fluid(maxWidth: 980) {
            ...GatsbyContentfulFluid
          } 
        }
      }
    }
  }
`;
